import { render, staticRenderFns } from "./Footer2.vue?vue&type=template&id=192487c9&scoped=true"
import script from "./Footer2.vue?vue&type=script&lang=ts"
export * from "./Footer2.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "192487c9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VFooter,VRow})
