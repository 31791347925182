






















import mixins from 'vue-typed-mixins'
import Footer from '@/calendesk/sections/footer/mixins/Footer'

export default mixins(Footer).extend({
  name: 'Footer2'
})
